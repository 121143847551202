<template>
  <q-layout
    class="c-layout shadow-2 rounded-borders"
    ref="layout"
    view="lHh Lpr fff"
  >
    <q-header>
      <q-toolbar color="primary" elevated :inverted="$q.theme === 'ios'">
        <q-btn
          icon="menu"
          flat
          round
          dense
          id="btnMenu"
          @click="
            () => {
              miniState = !drawer && isMobile;
              drawer = !drawer;
            }
          "
        />
        <q-btn
          id="btnLayVoltar"
          class="c-btn-voltar text-white"
          dense
          color="primary"
          :big="isMobile"
          icon="navigate_before"
          round
          v-show="!$route.meta.noBack"
          flat
          @click="voltar()"
        >
          <q-tooltip anchor="center right" self="center left" :delay="500">
            Voltar
          </q-tooltip>
        </q-btn>
        <div class="col q-ml-xs">
          <p class="q-pa-none q-ma-none titulo">{{ tituloPagina }}</p>
          <p class="q-pa-none q-ma-none descricao">{{ descricaoPagina }}</p>
        </div>
        <q-botao-tema />
      </q-toolbar>
    </q-header>
    <q-drawer
      v-model="drawer"
      show-if-above
      :mini="miniState"
      @mouseenter="miniState = false"
      @mouseleave="miniState = true"
      mini-to-overlay
      :width="200"
      :breakpoint="500"
      bordered
      content-class="bg-grey-3"
    >
      <q-scroll-area class="fit">
        <q-field class="logo">
          <img
            v-show="mostraLogoPequeno"
            :src="logoPequeno"
            style="width: 45%; height: 45%; margin-left: 30%; margin-top: 24%"
          />
          <img
            v-show="mostraLogoGrande"
            :src="logoGrande"
            style="width: 150px; height: 150px; margin-left: 25px"
          />
        </q-field>

        <q-list no-border link inset-delimiter padding>
          <q-item
            v-for="item in rotasVisiveis"
            :key="item.id"
            clickable
            v-ripple
            :to="item.path"
            :active-class="corItemAtivo"
            :id="`btn-${item.id}`"
          >
            <q-item-section avatar>
              <q-icon :name="item.icon" />
            </q-item-section>

            <q-item-section>
              {{ item.name }}
            </q-item-section>
          </q-item>

          <q-item
            clickable
            v-ripple
            @click="abrirLoja"
            v-if="!$store.getters.getModoTotem"
            id="btnSiteLoja"
          >
            <q-item-section avatar>
              <q-icon name="store" />
            </q-item-section>

            <q-item-section> Nosso Site </q-item-section>
          </q-item>

          <q-separator />

          <q-item clickable v-ripple id="btnLogout" @click="logout">
            <q-item-section avatar>
              <q-icon name="exit_to_app" />
            </q-item-section>

            <q-item-section> Sair </q-item-section>
          </q-item>
        </q-list>
        <q-item v-if="isMobile || !miniState" class="versao">
          <q-item-section class="row q-mr-md text-caption justify-end">
            Versão {{ appVersion }}
          </q-item-section>
        </q-item>
      </q-scroll-area>
    </q-drawer>
    <q-modal-usuario-inativo ref="modalUsuarioInativo" />
    <q-page-container @click="miniState = true">
      <router-view class="no-margin" />
    </q-page-container>
  </q-layout>
</template>

<script>
import login from "@/plugins/login";
import QBotaoTema from "@/components/shared/QBotaoTema";
import mixinInatividade from "@/mixins/mixinInatividade";
import ParametrosServices from "@/services/parametros";

export default {
  name: "LayoutDefault",

  components: {
    QBotaoTema,
  },

  mixins: [mixinInatividade],

  data() {
    return {
      urlBack: "",
      drawer: false,
      miniState: true,
      habilitaMeuBonus: true,
    };
  },

  created() {
    this.buscaParametros();
    if (this.isTotem) {
      this.monitorInatividade();
    }
  },

  computed: {
    rotas() {
      const rotasFiltradas = this.$router.options.routes[0].children.filter(
        (el) =>
          !el.hidden ||
          (el.desabilitaDependente &&
            Boolean(!login.usuarioLogado().id_dependente))
      );

      const rotasAtualizadas = rotasFiltradas.map((rota) => {
        if (rota.path === "/meu-bonus" && !this.habilitaMeuBonus) {
          return { ...rota, hidden: true };
        }
        return rota;
      });

      return rotasAtualizadas;
    },

    rotasVisiveis() {
      return this.rotas.filter((rota) => !rota.hidden);
    },

    corItemAtivo() {
      return this.$q.dark.isActive ? "text-grey-5" : "text-primary";
    },

    logoGrande() {
      return this.empresa === "gang"
        ? this.$q.dark.isActive
          ? `logo-${this.empresa}-min-white.png`
          : `logo-${this.empresa}-min.png`
        : `logo-${this.empresa}-min.png`;
    },

    logoPequeno() {
      return this.empresa === "gang"
        ? this.$q.dark.isActive
          ? `${this.empresa}-white.png`
          : `${this.empresa}.png`
        : `${this.empresa}.png`;
    },

    isMobile() {
      return this.$q.platform.is.mobile;
    },

    appVersion() {
      return process.env.VUE_APP_TAG;
    },

    empresa() {
      return process.env.VUE_APP_EMPRESA;
    },

    tituloPagina() {
      return `Cartão ${process.env.VUE_APP_DESCRICAO_EMPRESA}`;
    },

    descricaoPagina() {
      return this.$route.meta.title;
    },

    mobileMiniDrawer() {
      return this.isMobile && this.miniState && this.drawer;
    },

    mostraLogoPequeno() {
      return (
        (!this.isMobile && this.miniState && this.drawer) ||
        !this.drawer ||
        (this.mobileMiniDrawer && !this.$q.screen.lt.sm)
      );
    },

    mostraLogoGrande() {
      return (
        ((this.isMobile || !this.miniState || !this.drawer) &&
          !this.mobileMiniDrawer) ||
        (this.mobileMiniDrawer && this.$q.screen.lt.sm)
      );
    },

    isTotem() {
      return this.$store.getters.getModoTotem;
    },
  },

  methods: {
    async buscaParametros() {
      try {
        const [{ data: habilitaMeuBonus }] = await Promise.all([
          ParametrosServices.getParametro("AREA_CLIENTE.HABILITA_MEU_BONUS"),
        ]);
        this.habilitaMeuBonus = Boolean(Number(habilitaMeuBonus[0].valor));
      } catch (error) {
        console.error("Erro ao buscar parâmetros:", error);
      }
    },

    logout() {
      login.logout();
    },

    voltar() {
      this.$router.push("/")
    },

    abrirLoja() {
      window.open(process.env.VUE_APP_URL_ECOMMERCE);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-layout {
  #c-title {
    padding-left: 5px;
  }
  .logo.pomp {
    background-image: url("../assets/logo-pomp-min.png");
  }

  .logo.gang {
    background-image: url("../assets/logo-gang-min.png");
  }
}

.c-btn-menu-login {
  left: 96%;
  top: 1%;
  height: 30px;
}

.versao {
  text-align: right;
  margin-left: 10px;
  font-size: 10pt;
  color: #808080;
}

.titulo {
  font-size: 20px;
  font-weight: bold;
}

.descricao {
  margin-top: -8px;
}
</style>
